import React, { ReactElement } from 'react';
import { motion } from 'framer-motion';

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const MenuItem = ({ text, selected }: MenuItemProps): ReactElement => (
  <motion.div
    className="text-2xl mb-5 flex items-center cursor-pointer relative"
    variants={variants}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    {selected && (
      <div className="h-0.5 bg-white absolute -bottom-1 w-full md:w-1/2" />
    )}
    {text}
  </motion.div>
);

type MenuItemProps = {
  text: string;
  selected: boolean;
};

export default MenuItem;
