import { ReactElement } from 'react';
import { motion } from 'framer-motion';
import Link from 'next/link';
import SideBar from 'components/SideBar/SideBar';
import MenuItem from './MenuItem';

const links = [
  {
    text: 'Home',
    href: '/',
  },
  {
    text: 'Craft Boxes',
    href: '/collection/craft-boxes',
  },
  {
    text: 'Party Bags',
    href: '/collection/party-bags',
  },
  {
    text: 'Free Resources',
    href: '/collection/free-resources',
  },
  {
    text: 'Blog',
    href: '/blog',
  },
  { text: 'Workshops', href: '/events' },
  { text: 'About Us', href: '/about-us' },
  { text: 'Contact Us', href: '/contact-us' },
];

const variants = {
  open: {
    zIndex: 50,
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    zIndex: -1,
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const NavigationSide = ({
  navOpen,
  toggleNav,
  current,
}: NavigationSideProps): ReactElement => (
  <SideBar open={navOpen} toggleOpen={toggleNav} colour="main">
    <motion.div className="py-9 px-4" variants={variants}>
      {links.map(({ text, href }) => (
        <Link key={text} href={href} passHref>
          <a href={href} onClick={toggleNav}>
            <MenuItem selected={current === href} text={text} />
          </a>
        </Link>
      ))}
    </motion.div>
  </SideBar>
);

type NavigationSideProps = {
  navOpen: boolean;
  toggleNav: () => void;
  current: string;
};

export default NavigationSide;
